import { GenericSlotFactory } from 'base/components/GenericSlot';
import QuoteboxTheme from './theme';

const $ = GenericSlotFactory({ theme: QuoteboxTheme });

const Base = $({ as: 'svg', slot: 'base' });
const Quote = $({ as: 'blockquote', slot: 'quote' });
const ForeignObject = $({ as: 'foreignObject', slot: 'object' });

export const Quotebox = Object.assign(Base, { Quote, ForeignObject });
